/* eslint-disable react/no-multi-comp */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import onClickOutside from 'react-onclickoutside';
import { geolocationConnect, GeolocationProp } from 'geolocation';
import map from 'lodash/map';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import filter from 'lodash/filter';
import last from 'lodash/last';
import findIndex from 'lodash/findIndex';
import isNumber from 'lodash/isNumber';
import Bem from 'bemHelper';
import { I18nHoc } from 'helpers/i18n';
import cacheTerms from 'helpers/cacheTerms';
import DataProvider from 'api/data-provider';
import { dictConnect } from 'api/data-dict';
import { ComboboxInputWrapper, Input, InputLabel } from 'containers/form';
import Icon, { iconTypes } from 'components/icon';
import Button from 'components/button';
import { ModalButton, Content } from 'components/modal';

import AbstractInput from './abstractInput';

import translates from './input-locality-i18n.json';

import 'styles/base/containers/form/_input-locality.scss';

const DEFAULT_LOCATION = 1;
export const orderBigCitiesId = [2, 133, 182, 114, 60];

@geolocationConnect()
class InputLocality extends AbstractInput {
  static propTypes = {
    ...AbstractInput.propTypes,
    geolocate: PropTypes.bool,
    needLabel: PropTypes.bool,
    estateType: PropTypes.string,
    // Project for objects count query
    project: PropTypes.string,
    // Type for objects count query
    countColumnType: PropTypes.string,
    showTopRegions: PropTypes.bool,
    noError: PropTypes.bool,
    // Ask user for locality confirmation
    confirmLocality: PropTypes.bool
  };

  static defaultProps = {
    geolocate: true,
    confirmLocality: false,
    noError: true,
    showTopRegions: false
  };

  constructor(props, context) {
    super(props, context);
    this.state = {};
    this.refLocalitySelector = null;

    this.handleConfirmLocality = this.handleConfirmLocality.bind(this);
    this.changeLocalitySelector = this.changeLocalitySelector.bind(this);
  }

  componentDidMount() {
    this.geolocate(this.props);
  }

  componentDidUpdate() {
    this.geolocate(this.props);
  }

  geolocate(props) { // eslint-disable-line
    if (props.value !== null) return;
    if (!props.geolocate) return;

    const { geolocation = {}, confirmLocality } = props;
    const { location = {}, confirmed } = geolocation;

    if ((confirmed || !confirmLocality) && location && location.id) {
      props.onChange(location.id);
    } else if (
      props.geolocationComplete &&
      geolocation.status === 'unlocated'
    ) {
      props.onChange(DEFAULT_LOCATION);
    }
  }

  /**
   * Set locality as confirmed by user and
   * update server geolocation to this locality
   *
   * @param id
   */
  changeConfirmedLocality = (id) => {
    const { confirmLocality } = this.props;
    if (!confirmLocality) return null;
    this.props.geolocationForceUpdate(id);
  };

  _setLocalitySelectorRef = el =>
    this.refLocalitySelector = el;

  handleConfirmLocality() {
    const { geolocation } = this.props;
    const { location = {} } = geolocation;
    this.changeConfirmedLocality(location.id);
    this.props.onChange(location.id);
  }

  changeLocalitySelector() {
    this.refLocalitySelector.openRegionSelector();
  }

  render() {
    const { className, confirmLocality, geolocation } = this.props;
    const { status, location = {}, confirmed } = geolocation;
    const { declension = {} } = location || {};

    return (
      <>
        <DataProvider
          className={bemClassesInput({ extra: className }).className}
          url="domik/location/administrative"
          injectPropName="selected"
          cache={cacheTerms.loc_administrative}
          query={{ id: this.props.value || DEFAULT_LOCATION }}
          lruCache
          disableLoader
        >
          <LocalitySelector
            {...this.props}
            changeConfirmedLocality={this.changeConfirmedLocality}
            ref={this._setLocalitySelectorRef}
          />
        </DataProvider>
        {status === 'located' && confirmLocality && !confirmed && location && location.id &&
          <PromptLocalitySelector
            declension={declension}
            location={location}
            changeLocalitySelector={this.changeLocalitySelector}
            handleConfirmLocality={this.handleConfirmLocality}
          />
        }
      </>
    );
  }
}

const bemClassesInput = new Bem('inputLocality');

@onClickOutside
@I18nHoc(translates)
class PromptLocalitySelector extends Component {
  static propTypes = {
    enableOnClickOutside: PropTypes.func,
    disableOnClickOutside: PropTypes.func,
    changeLocalitySelector: PropTypes.func,
    handleConfirmLocality: PropTypes.func,
    declension: PropTypes.shape({}),
    location: PropTypes.shape({}),
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      showPromptLocality: true
    };

    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  handleClickOutside() {
    this.setState({
      showPromptLocality: false
    });
  }

  render() {
    if (!this.state.showPromptLocality) return null;

    const { i18n } = this;
    const { declension, location, changeLocalitySelector, handleConfirmLocality } = this.props;

    return (
      <div {...bemClassesInput('prompt')}>
        <div {...bemClassesInput('arrow')} />
        <div {...bemClassesInput('promptContent')}>
          <div {...bemClassesInput('question')}>
            <span {...bemClassesInput('text', 'question')} children={i18n('regionSelect', { placeholder: get(declension, 'I') || location.display_name })} />
          </div>
          <Button
            {...bemClassesInput('btn', 'yes')}
            onClick={handleConfirmLocality}
            label={i18n('yes')}
            title={i18n('yes')}
          />
          <Button
            {...bemClassesInput('btn', 'change')}
            onClick={changeLocalitySelector}
            label={i18n('edit')}
            title={i18n('locationChange')}
          />
        </div>
      </div>
    );
  }
}

function localitySelectorGetSelected(props) {
  const { selected = [] } = props;
  return selected[0] ? selected[0] : {};
}

class LocalitySelector extends Component {
  static propTypes = {
    className: PropTypes.string,
    value: PropTypes.number,
    onChange: PropTypes.func,
    onLocalityDefined: PropTypes.func,
    selectedReloadCount: PropTypes.number,
    geolocation: GeolocationProp,
    schema: PropTypes.shape({}),
    validationState: PropTypes.shape({}),
    // TODO REMOVE FUCKING ICONSECONDTYPE
    iconSecondType: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool
    ]),
    needLabel: PropTypes.bool,
    // Project for objects count query
    project: PropTypes.string,
    // Type for objects count query
    countColumnType: PropTypes.string,
    estateType: PropTypes.string,
    showTopRegions: PropTypes.bool,
    noError: PropTypes.bool,
    left: PropTypes.bool,
    wAuto: PropTypes.bool,
    changeConfirmedLocality: PropTypes.func,
    designMode: PropTypes.oneOf(['modal', 'combobox']),
    modalBtnProps: PropTypes.shape({})
  };

  static defaultProps = {
    designMode: 'combobox'
  };

  constructor(props, context) {
    super(props, context);
    this.handleParentChange = this.handleParentChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);

    const sel = localitySelectorGetSelected(props);
    this.state = {
      parent: null,
      search: null
    };

    if (sel) {
      this.state.parent = (sel.type === 'country' || sel.has_child_locality) ? sel.id : sel.parent;
    }
  }

  componentDidMount() {
    const sel = localitySelectorGetSelected(this.props);
    if (sel.id && this.props.onLocalityDefined) {
      this.props.onLocalityDefined(sel);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.selectedReloadCount !== prevProps.selectedReloadCount &&
      this.props.onLocalityDefined
    ) {
      const sel = localitySelectorGetSelected(this.props);
      this.props.onLocalityDefined(sel);
    }
  }

  openRegionSelector = () => {
    if (this.comboWrapperRef) { this.comboWrapperRef.openDropDown(); }
    if (this.modalWrapperRef) { this.modalWrapperRef.toggle(); }
  }

  handleChange(val) {
    this.setState({
      parent: (val.type === 'country' || val.has_child_locality) ? val.id : val.parent,
      search: null
    });
    this._closeRegionSelector();
    this.props.onChange(val.id, val);
    this.props.changeConfirmedLocality(val.id);
  }

  handleSearch(val) {
    this.setState({
      search: val
    });
  }

  handleParentChange(val) {
    this.setState(() => ({
      parent: val.id,
      search: null
    }));
  }

  _closeRegionSelector() {
    if (this.comboWrapperRef) { this.comboWrapperRef.closeDropDown(); }
    if (this.modalWrapperRef) { this.modalWrapperRef.closeModal(); }
  }

  _renderRegionSelector() {
    const selected = localitySelectorGetSelected(this.props);
    const {
      geolocation = {}, value, estateType, project, countColumnType, showTopRegions
    } = this.props;

    let provider = 'domik/location/administratives';
    let query = {
      parent: this.state.parent,
      columnObjectCount: {
        project,
        type: countColumnType,
        class: estateType
      }
    };

    let cache = cacheTerms.loc_administrative;
    // const required = schema.required;
    if (this.state.search) {
      cache = null;
      provider = 'common/location/geocoder';
      query = {
        suggest: this.state.search,
        mode: 'locality',
        hasChildLocality: true,
        columnObjectCount: {
          project,
          type: countColumnType,
          class: estateType
        }
      };
      if (geolocation.point) {
        query.center = geolocation.point;
      }
      if (geolocation.location) {
        query.bbox = geolocation.location.geo_bbox;
      }
    }

    return (
      <>
        <DataProvider
          url={provider}
          query={query}
          queryJson
          cache={cache}
          renderUnloaded
          disableLoader
        >
          <LocalityList
            selected={selected}
            parent={this.state.parent}
            value={value}
            search={this.state.search}
            onParentChange={this.handleParentChange}
            onChange={this.handleChange}
            onSearch={this.handleSearch}
            closeWrapperDD={() => this._closeRegionSelector()}
            estateType={estateType}
            project={project}
            countColumnType={countColumnType}
            showTopRegions={showTopRegions}
          />
        </DataProvider>
      </>
    );
  }

  _renderComboboxMode() {
    const selected = localitySelectorGetSelected(this.props);
    const { left, wAuto, noError, value, iconSecondType } = this.props;

    return (
      <ComboboxInputWrapper
        {...bemClassesInput('comboboxInputWrapper')}
        ref={el => this.comboWrapperRef = el}
        onDDClose={() => this.handleParentChange({ id: this.state.parent })}
        noError={noError}
        valueSelected={isNumber(value)}
        iconSecondType={iconSecondType}
        disableContent
        wAuto={wAuto}
        left={left}
      >
        {selected.display_name}
        <div {...bemClassesInput('content')} children={this._renderRegionSelector()} />
      </ComboboxInputWrapper>
    );
  }

  _renderModalMode(modalBtnProps) {
    const selected = localitySelectorGetSelected(this.props);

    return (
      <ModalButton
        {...bemClassesInput('btn', 'locality')}
        buttonProps={{
          ...modalBtnProps,
          label: selected.display_name
        }}
        classNameModal="modalLocality"
        size="content"
        ref={el => this.modalWrapperRef = el}
        dataTest="locality"
      >
        <Content noPadding closeButton><div children={this._renderRegionSelector()} /></Content>
      </ModalButton>
    );
  }

  render() {
    const {
      className, schema = {}, value, validationState, needLabel, designMode, modalBtnProps
    } = this.props;

    // if (!this.state.parent) return null; // What is the shit???

    return (
      <div {...bemClassesInput({ extra: className })}>
        {needLabel &&
          <InputLabel schema={schema} value={value} validationState={validationState} inside />
        }
        {designMode === 'modal' ? this._renderModalMode(modalBtnProps) : this._renderComboboxMode()}
      </div>
    );
  }
}

const bemClassesCrumbs = new Bem('breadcrumbs');
/**
 * Big city renderer
 */
@dictConnect({
  url: 'domik/search-form/bigcities',
  injectPropName: 'bigcities'
})
class BigCities extends Component {
  static propTypes = {
    className: PropTypes.string,
    onChange: PropTypes.func,
    onSelectFull: PropTypes.func,
    data: PropTypes.any, // eslint-disable-line
    bigcities: PropTypes.any, // eslint-disable-line
    bigcitiesIndex: PropTypes.shape({})
  };

  selectorButton(val, label, key) {
    return (
      <Button
        key={key}
        {...bemClassesCrumbs('btn', 'country')}
        onClick={() => this.props.onSelectFull(val)}
        label={label}
      />
    );
  }

  render() {
    const orderBigCities = filter(
      map(orderBigCitiesId, val => this.props.bigcitiesIndex[val]), it => !!it
    );

    return (
      <div {...bemClassesCrumbs({ extra: this.props.className })}>
        {map(orderBigCities, (val, key) =>
          this.selectorButton(val, val.display_name, key)
        )}
      </div>
    );
  }
}

/**
 * Region breadcrumbs renderer
 */
@I18nHoc(translates)
class Breadcrumbs extends Component {
  static propTypes = {
    className: PropTypes.string,
    onChange: PropTypes.func,
    onSelectFull: PropTypes.func,
    rendererTopContent: PropTypes.func,
    data: PropTypes.any, // eslint-disable-line
    selected: PropTypes.any, // eslint-disable-line
    estateType: PropTypes.string,
    // Project for objects count query
    project: PropTypes.string,
    // Type for objects count query
    countColumnType: PropTypes.string,
    showTopRegions: PropTypes.bool,
  };

  static getDerivedStateFromProps(props, state) {
    const newState = {
      lastData: props.data
    };
    if (!isEqual(props.data, state.lastData)) newState.showSelected = false;
    return newState;
  }

  constructor(props, ctx) {
    super(props, ctx);
    this.state = {
      showSelected: true
    };
  }

  selectorButton(val, label, key) {
    return (
      <Button
        key={key}
        {...bemClassesCrumbs('btn', last(this.props.data).type === 'country' ? 'country' : 'cur')}
        onClick={() => this.props.onSelectFull(val)}
        label={label}
      />
    );
  }

  renderMainCrumbs(data = []) {
    const { selected } = this.props;
    const dataLength = data.length - 1;
    const noActionBtn = (val) => {
      if (val.display_name) {
        return (
          <span {...bemClassesCrumbs('btn', 'gray')} title={val.display_name}>
            <span {...bemClassesCrumbs('btnContent')}>
              <span {...bemClassesList('text')}>{val.display_name}</span>
            </span>
          </span>
        );
      }
      return null;
    };

    let selectedIndex = -1;
    if (selected) selectedIndex = findIndex(data, it => it.id === selected.id);

    return (
      <>
        {map(data, (val, key) => (
          <span {...bemClassesCrumbs('item')} key={key}>
            {(
              (this.state.showSelected && selectedIndex !== key) ||
              key < dataLength
            )
              ? (
                <>
                  <Button
                    {...bemClassesCrumbs('btn')}
                    onClick={() => {
                      this.setState({ showSelected: false });
                      this.props.onChange(val);
                    }}
                    title={val.display_name}
                    label={val.display_name}
                  />
                  <span {...bemClassesCrumbs('btn', 'arrow')}>
                    <span {...bemClassesCrumbs('btnContent')}>
                      <Icon {...bemClassesCrumbs('icon')} type={iconTypes.arrowSliderRight} />
                    </span>
                  </span>
                </>
              )
              : noActionBtn(val)
            }
          </span>
        ))}
        {this.state.showSelected && selectedIndex < 0 && selected &&
          <span {...bemClassesCrumbs('item')}>
            {noActionBtn(selected)}
          </span>
        }
      </>
    );
  }

  render() {
    const { i18n } = this;
    const selectFullDict = {
      country: i18n('wholeCountry'),
      region: i18n('wholeRegion'),
      autonom_republic: i18n('wholeRegion'),
      region_district: i18n('wholeDistrict'),
      city: i18n('wholeCity'),
      bigcity: i18n('wholeCity'),
      village: i18n('wholeVillage'),
      settlement: i18n('wholeSettlement'),
      urban_settlement: i18n('wholeSettlement')
    };
    const cur = last(this.props.data);
    let treeTopRegion = cur.tree;
    if (this.state.showSelected
      && this.props.selected
      && this.props.selected.tree) {
      treeTopRegion = this.props.selected.tree;
    }
    const data = this.props.data;
    const rightMenu = (this.state.showSelected && this.props.selected) ?
      this.selectorButton(
        this.props.selected, selectFullDict[this.props.selected.type]
      ) :
      this.selectorButton(
        cur, selectFullDict[cur.type]
      );
    let idNot = null;
    if (this.state.showSelected && this.props.selected && this.props.selected.id) {
      idNot = this.props.selected.id;
    } else if (!this.state.showSelected && cur && cur.id) {
      idNot = cur.id;
    }

    return (
      <>
        <div {...bemClassesCrumbs({ extra: this.props.className })}>
          {this.renderMainCrumbs(data)}
          {rightMenu}
        </div>
        {this.props.showTopRegions &&
          <DataProvider
            url="domik/location/administratives"
            queryJson
            query={{
              topRegions: {
                project: this.props.project,
                type: this.props.countColumnType,
                class: this.props.estateType,
                tree: treeTopRegion
              },
              columnObjectCount: {
                project: this.props.project,
                type: this.props.countColumnType,
                class: this.props.estateType
              },
              idNot
            }}
            renderer={this.props.rendererTopContent}
          />
        }
      </>
    );
  }
}

const bemClassesList = new Bem('localityList');
/**
 * Selector renderer
 */
@I18nHoc(translates)
class LocalityList extends Component {
  static propTypes = {
    className: PropTypes.string,
    parent: PropTypes.number,
    value: PropTypes.number, // eslint-disable-line
    search: PropTypes.string,
    onParentChange: PropTypes.func,
    onChange: PropTypes.func,
    onSearch: PropTypes.func,
    data: PropTypes.any, // eslint-disable-line
    selected: PropTypes.any, // eslint-disable-line
    closeWrapperDD: PropTypes.func,
    estateType: PropTypes.string,
    // Project for objects count query
    project: PropTypes.string,
    // Type for objects count query
    countColumnType: PropTypes.string,
    showTopRegions: PropTypes.bool
  };

  constructor(props, ctx) {
    super(props, ctx);
    this.handleGoChildren = this.handleGoChildren.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.rendererContent = this.rendererContent.bind(this);
    this.rendererTopContent = this.rendererTopContent.bind(this);
    this.state = {
      searchValue: props.search
    };
  }

  handleGoChildren(val) {
    this.setState({ searchValue: null });
    this.props.onParentChange(val);
  }

  handleSelect(val) {
    this.setState({ searchValue: null });
    this.props.onChange(val);
  }

  handleSearchChange(val) {
    this.setState({ searchValue: val });

    const { onSearch } = this.props;

    if (val && val.length >= 3 && onSearch) {
      onSearch(val);
    } else {
      onSearch(null);
    }
  }

  renderItem(val, key, isSearch, showCount) {
    return (
      <div
        {...bemClassesList('btnContainer')}
        key={key}
      >
        <Button
          {...bemClassesList('btn')}
          onClick={() =>
            val.has_child_locality ?
              this.handleGoChildren(val) : this.handleSelect(val)
          }
          label={isSearch ? val.display_path_reverse : val.display_name}
          labelSecond={showCount ? val.objects_count : false}
        />
      </div>
    );
  }

  renderSimpleItems(data, types, noTypes) {
    return filter(map(data, (val, key) =>
      ((types.indexOf(val.type) > -1 || types.indexOf(val.metatype) > -1) &&
       (noTypes.indexOf(val.type) === -1 && noTypes.indexOf(val.metatype) === -1)
        ? this.renderItem(val, key, false, true) : null)
    ), val => val !== null);
  }

  rendererTopContent(data = {}) {
    this.topRegionsCount = (data.data && data.data.length) || 0;
    if (this.topRegionsCount) {
      return (
        <>
          <div {...bemClassesList('localities')}>
            {map(data.data, (val, key) => this.renderItem(val, key, false, true))}
          </div>
          {/*
          <div {...bemClassesList('separate')} children={<span {...bemClassesList('line')} />} />
          */}
        </>
      );
    }

    return null;
  }

  renderSimpleList(data) {
    const bigcities = this.renderSimpleItems(data, ['bigcity'], []);
    const localities = this.renderSimpleItems(data, ['locality'], ['bigcity']);
    const regions = this.renderSimpleItems(data, ['region', 'subregion'], []);

    return (
      <>
        {bigcities.length > 0 && <div {...bemClassesList('bigcities')} children={bigcities} data-test="bigcities" />}
        {localities.length > 0 && <div {...bemClassesList('localities')} children={localities} />}
        {/*
        {(localities.length > 0 && regions.length > 0) &&
          <div {...bemClassesList('separate')} children={<span {...bemClassesList('line')} />} />
        }
        */}
        {regions.length > 0 && <div {...bemClassesList('regions')} children={regions} />}
      </>
    );
  }

  renderSearchList(data) {
    return (
      <div {...bemClassesList('localities')}>
        {map(data, (val, key) => this.renderItem(val, key, true, false))}
      </div>
    );
  }

  rendererContent(data) {
    const { data: parentData, onChange, selected, search, className } = this.props;
    const list = search ?
      this.renderSearchList(parentData) :
      this.renderSimpleList(parentData);

    return (
      <div {...bemClassesList({ extra: className })} data-test="localityList">
        <div {...bemClassesList('top')}>
          <div {...bemClassesList('search')}>
            <Input
              {...bemClassesList('searchInput')}
              onChange={this.handleSearchChange}
              value={this.state.searchValue}
              schema={{
                placeholder: this.i18n('search')
              }}
              noError
            />
          </div>
          <div {...bemClassesList('topRight')} data-test="topRightList">
            <BigCities
              onChange={this.handleGoChildren}
              onSelectFull={onChange}
              data={data.data}
            />
            {/*
            <Button
              {...bemClassesList('btn', 'close')}
              onClick={this.props.closeWrapperDD}
              iconType="clear"
              noPadding
              />
            */}
          </div>
        </div>
        <div {...bemClassesList('breadcrumbs', { search })} data-test="breadcrumbs">
          <Breadcrumbs
            onChange={this.handleGoChildren}
            onSelectFull={onChange}
            data={data.data}
            selected={selected}
            estateType={this.props.estateType}
            project={this.props.project}
            countColumnType={this.props.countColumnType}
            showTopRegions={this.props.showTopRegions}
            rendererTopContent={this.rendererTopContent}
          />
        </div>
        {list}
      </div>
    );
  }

  render() {
    return (
      <DataProvider
        url="domik/location/administrative"
        query={{ parentsById: this.props.parent }}
        cache={cacheTerms.loc_administrative}
        renderer={this.rendererContent}
        disableLoader
      />
    );
  }
}

export default InputLocality;
