/**
 * Contains constants with standart cache terms for different entities
 * Cache terms defines in MINUTES
 * @type {number}
 */

const DAY = 1440;

const terms = {
  // Information about location administrative units
  loc_administrative: DAY * 3,

  // Standart landing pages
  standartLandingPage: 60,

  // Long-term updateable pages
  longPage: DAY / 2,

  // Long-term updateable pages
  shortPage: 300,

  // Standart list providers in pages
  standartInfoProvider: 60,

  // Rarely, long-term updateable providers in pages
  longInfoProvider: DAY,

  // Cache comments provider for preloader/unauthorized
  // Comments subsystem invalidates cache, when new comment added or edited
  // Authorized users see uncached comments
  commentsList: 60,

  // *** Forum
  // Cache for forums list
  forumsList: 10,
};

export default terms;
